export default [
  'working_time',
  'average_loading_duration',
  'idle_time',
  'technical_downtime',
  'operation_time',
  'cycle_duration_avg',
  'cycle_duration_sum',
  'working_time_driving_unloaded',
  'working_time_driving_loaded',
  'idle_time_unloaded',
  'idle_time_loaded',
  'average_dumping_duration',
  'hydraulic_time',
  'cutting_time',
  'tramming_time',
  'conveyor_time',
  'loading_time',
  'activity_time',
  'drill_time_sum',
  'drill_time_hole_avg',
  'bolt_time_sum',
  'bolt_time_avg',
  'hydraulic_idle_time_sum',
  'diesel_idle_time_sum',
  'electrical_operating_time_sum',
  'diesel_operating_time_by_activity',
  'drill_time_histogram',
  'bolt_time_histogram',
]
