<template>
  <TableWidgetStyled>
    <TableV2
      :title="$tc('widgetTypes.TABLE')"
      :headers="headers"
      :rows="rows"
      :sorting.sync="sorting"
      :filtersAvailable="filtersAvailable"
      :filterOptionsSelected="filterOptionsSelected"
      :isLoading="isLoading"
      :searchQuery="searchQuery"
      :totals="totalCols"
      :enableSearch="true"
      :isMobile="isMobile"
      :enableFooter="true"
      :messages="messages"
      @tableExport="tableExport"
      @textFilter="textSearch"
      @setSelectedFilterOption="setSelectedFilterOption"
      @resetFilters="resetFilters"
    />
  </TableWidgetStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import { TimeframeMixin } from '@common/mixins'
import resizeMixin from '@/mixins/resize'
import localesMixin from '@/mixins/locales'
import units, { convertToLocalUnitSystem } from '@/utils/units'
import { exportTableDataToCSV } from '@/utils/export'
import { DateTime, Interval } from 'luxon'

import TableV2 from '@/components/Atomic/Molecules/TableV2.vue'
import { TABLE_SORT_TYPES } from '@/constants/settings'
import TableIconCell from '../Atomic/Atoms/TableCells/TableIconCell.vue'
import TableRouterLinkCell from '../Atomic/Atoms/TableCells/TableRouterLinkCell.vue'
import { compact, concat, groupBy, orderBy, partition, uniqBy, get } from 'lodash'
import fullTimeDurationFormatter from '@/utils/duration'
import assetDimensionWithDurationFormat from '@/utils/widgets/assetDimensionWithDurationFormat'
import { getAssetDimensionNameByLocale } from '@common/utils/src'
import { useAssetStore } from '@/stores/assets'

import ASSET_DIMENSION_DATA_QUERY from '#/graphql/assetDimensions/assetDimensionDataTableWidget.gql'

export const TableWidgetStyled = styled('div')`
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.navFontNormal};
`

export default {
  setup() {
    const assetStore = useAssetStore()
    return {
      assetStore,
    }
  },
  mixins: [TimeframeMixin, resizeMixin, localesMixin],
  inject: ['uiSettings'],
  props: {
    isPreview: {
      type: Boolean,
      default: false,
    },
    isMobilePreview: {
      type: Boolean,
      default: false,
    },
    widget: {
      type: Object,
      required: true,
    },
  },
  components: {
    TableWidgetStyled,
    TableV2,
  },
  data() {
    return {
      isMobile: true,
      searchQuery: '',
      sorting: {
        key: 'type.name',
        asc: true,
      },
      filterOptionsSelected: [],
      noData: false,
      totals: {},
      aggrByAsset: {},
    }
  },
  computed: {
    assetsSelected() {
      return this.assetStore.assetsSelected()
    },
    assetIds() {
      return this.assetsSelected.map(({ id }) => id)
    },
    dimensions() {
      const dimensions = this.widget?.dimensions ?? []
      return dimensions.sort((a, b) => {
        return a.position - b.position
      })
    },
    locale() {
      const userLocale = this.uiSettings?.dates ?? 'DE_DE'
      const replacedDates = userLocale.replace('_', '-')
      return replacedDates.slice(0, 2)
    },
    hasDecimalFormatEnabled() {
      return this.widget?.widgetSettings?.hasDecimalFormatEnabled
    },
    uniqueAssetTypes() {
      return uniqBy(this.assetsSelected, 'type.name').map(asset => asset.type)
    },
    isLoading() {
      return this.$apollo.loading
    },
    assetDimensions() {
      return this.dimensions.map(dimension => {
        const assetDimension = get(dimension, 'assetDimension', '')
        return {
          ...assetDimension,
        }
      })
    },
    filtersAvailable() {
      const filters = [
        {
          id: 'filter_asset_type',
          class: 'filter filter-asset-types',
          modelName: 'MachineType',
          placeholder: this.$tc('assetType', 2),
          searchable: false,
          options: this.uniqueAssetTypes.map(m => ({
            ...m,
            id: m.id,
            label: this.$t(`assetTypes.${m.name.toLowerCase()?.split(' ').join('')}`),
          })),
          value: this.filterOptionsSelected
            .filter(f => f.__typename === 'MachineType')
            .map(val => {
              return {
                ...val,
                label: this.$t(`assetTypes.${val.name.toLowerCase()?.split(' ').join('')}`),
              }
            }),
          isMultiple: true,
        },
      ]
      return filters
    },
    mappedData() {
      return this.assetsSelected.map(asset => {
        const dimensions = this.dimensions.map(dimension => {
          const assetDimensionName = get(dimension.assetDimension, 'name', '')
          const aggr = get(this.aggrByAsset, assetDimensionName, []).find(f => f.assetId === asset.id)
          const value = get(aggr, 'floatValue', null)
          const data = value
          const id = `${dimension.assetDimension.id}`
          return {
            id,
            data,
          }
        })
        return {
          ...asset,
          dimension: dimensions,
        }
      })
    },
    assetsFiltered() {
      if (this.filterOptionsSelected.length > 0) {
        return this.mappedData.reduce((assetsFiltered, asset) => {
          const filterGroups = groupBy(this.filterOptionsSelected, '__typename')
          const notInAllGroups = Object.keys(filterGroups).some(key => {
            const filterGroup = filterGroups[key]
            let assetPath
            let filterPath
            if (key === 'MachineType') {
              assetPath = 'type.id'
              filterPath = 'id'
            } else {
              throw new Error(`unhandled filter type ${key}`)
            }
            const found = filterGroup.find(filter => get(filter, filterPath) === get(asset, assetPath))
            return !found
          })
          if (!notInAllGroups) {
            assetsFiltered.push(asset)
          }
          return assetsFiltered
        }, [])
      }
      return this.mappedData
    },
    assetsSorted() {
      const partitions = partition(this.assetsFiltered, x => !!get(x, this.sorting.key, null))
      const sortDirection = this.sorting.asc ? 'asc' : 'desc'
      return concat(orderBy(partitions[0], [this.sorting.key], [sortDirection]), partitions[1])
    },
    headers() {
      const cols = [
        {
          id: `header_type`,
          label: this.$tc('type', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'type.name',
          size: 'xsmall',
        },
        {
          id: `header_name`,
          label: this.$tc('asset', 1),
          sortType: TABLE_SORT_TYPES.A_TO_Z,
          isSortable: true,
          sortKey: 'name',
          size: 'medium',
        },
      ]
      let count = 0
      const c = cols.concat(
        this.dimensions.map(dimension => {
          const title = get(dimension, 'title', null)
          const name = dimension?.assetDimension?.nameTranslations
            ? getAssetDimensionNameByLocale(dimension?.assetDimension?.nameTranslations, this.locale)
            : dimension?.assetDimension?.name
          const physicalUnitSI = get(dimension, 'assetDimension.physicalUnitSI', '')
          const physicalUnitUIMetric = get(dimension, 'assetDimension.physicalUnitUIMetric', null)
          const physicalUnitUIImperial = get(dimension, 'assetDimension.physicalUnitUIImperial', null)
          let unit = convertToLocalUnitSystem(this.selectedUIUnitSystem, physicalUnitUIMetric, physicalUnitUIImperial)
          if (unit) {
            unit = unit.replace('mt', 't')
          } else {
            unit = physicalUnitSI
          }
          count = count + 1
          return {
            id: `header_${dimension.assetDimension.id}`,
            label: title || name,
            unit: unit,
            isSortable: true,
            sortType: TABLE_SORT_TYPES.LOW_TO_HIGH,
            sortKey: `dimension[${count - 1}].data`,
            size: 'medium',
          }
        }),
      )
      return c
    },
    rows() {
      const rows = this.assetsSorted.map(asset => {
        let cells = [
          {
            id: `${asset.id}_type`,
            sortableValue: asset?.type?.name,
            component: TableIconCell,
            icon: asset.type?.name?.split(' ').join(''),
            headerId: 'header_type',
          },
          {
            id: `${asset.id}_name`,
            sortableValue: asset?.name,
            component: TableRouterLinkCell,
            linkName: 'AssetOverview',
            linkLabel: asset?.name,
            linkParams: { id: asset.id },
            isMobilePrimarycol: true,
            mobilePrimarycolLabel: asset?.name,
            headerId: `header_name`,
          },
        ]
        const dimensionData = this.dimensions.map(dimension => {
          const assetDimensionName = get(dimension.assetDimension, 'name', '')
          const aggr = get(this.aggrByAsset, assetDimensionName, []).find(f => f.assetId === asset.id)
          const value = get(aggr, 'floatValue', null)
          let data = ''
          if (value === null) {
            data = '-'
          } else {
            const unitSI = get(dimension, 'assetDimension.physicalUnitSI', null)
            const unitUIMetric = get(dimension, 'assetDimension.physicalUnitUIMetric', '')
            const unitUIImperial = get(dimension, 'assetDimension.physicalUnitUIImperial', null)
            const unitUI = unitUIImperial && this.selectedUIUnitSystem === 'IMPERIAL' ? unitUIImperial : unitUIMetric
            const isDurationFormatAvailable = assetDimensionWithDurationFormat.includes(assetDimensionName)
            data =
              unitSI === 's' && !this.hasDecimalFormatEnabled && isDurationFormatAvailable
                ? fullTimeDurationFormatter(value)
                : units(value, unitSI, unitUI, 2, false, true, false, this.thousandsSeperator, this.decimalSeperator, true)
          }
          return {
            id: dimension.assetDimension.id,
            sortableValue: data,
            headerId: `header_${dimension.assetDimension.id}`,
          }
        })
        cells = cells.concat(dimensionData)
        return {
          rowId: `row_${asset.id}`,
          cells,
        }
      })
      const validData = this.dimensions.find(dimension => this.totals[dimension.assetDimension.name] !== null)
      if (!validData) {
        return []
      } else {
        const filteredTable = rows.filter(row => {
          const props = compact(row.cells.map(item => item.sortableValue))
          return props.some(
            prop =>
              !this.searchQuery ||
              (typeof prop === 'string'
                ? prop.toLowerCase().includes(this.searchQuery.toLowerCase())
                : prop.toString(10).includes(this.searchQuery)),
          )
        })
        return filteredTable
      }
    },
    totalCols() {
      let cells = [
        {
          id: 'total_type',
          sortableValue: '',
          headerId: 'header_type',
        },
        {
          id: 'total_name',
          sortableValue: this.$tc('total'),
          headerId: `header_name`,
        },
      ]
      cells = cells.concat(
        this.dimensions.map(dimension => {
          const assetDimensionName = get(dimension.assetDimension, 'name', '')
          const value = get(this.totals, assetDimensionName, null)
          let data = ''
          if (value === null) {
            data = '-'
          } else {
            const unitSI = get(dimension, 'assetDimension.physicalUnitSI', null)
            const unitUIMetric = get(dimension, 'assetDimension.physicalUnitUIMetric', null)
            const unitUIImperial = get(dimension, 'assetDimension.physicalUnitUIImperial', null)
            const unitUI = convertToLocalUnitSystem(this.selectedUIUnitSystem, unitUIMetric, unitUIImperial)
            const isDurationFormatAvailable = assetDimensionWithDurationFormat.includes(assetDimensionName)
            data =
              unitSI === 's' && !this.hasDecimalFormatEnabled && isDurationFormatAvailable
                ? fullTimeDurationFormatter(value)
                : units(value, unitSI, unitUI, 2, false, true, false, this.thousandsSeperator, this.decimalSeperator, true)
          }
          return {
            id: `total_${assetDimensionName}`,
            sortableValue: data,
            headerId: `header_${dimension.assetDimension.id}`,
          }
        }),
      )
      return [
        {
          rowId: `total`,
          cells,
        },
      ]
    },
    CSVHeaders() {
      const cols = [this.$tc('asset', 1)]
      const csvHeaderColumns = cols.concat(
        this.assetDimensions.map(dimension => {
          const name = dimension?.nameTranslations
            ? getAssetDimensionNameByLocale(dimension?.nameTranslations, this.locale)
            : dimension?.name
          const physicalUnitUIMetric = get(dimension, 'physicalUnitUIMetric', null)
          const physicalUnitUIImperial = get(dimension, 'physicalUnitUIImperial', null)
          let unit = convertToLocalUnitSystem(this.selectedUIUnitSystem, physicalUnitUIMetric, physicalUnitUIImperial)
          unit = unit ? unit.replace('mt', 't') : dimension.physicalUnitSI
          return `${name} ${unit}`
        }),
      )
      return csvHeaderColumns
    },
    CSVRows() {
      return this.assetsSelected.map(asset => {
        const assetId = asset.id
        let columns = [asset.name]
        columns = columns.concat(
          this.assetDimensions.map(dimension => {
            const assetDimensionName = dimension.name
            const aggr = get(this.aggrByAsset, assetDimensionName, []).find(f => f.assetId === assetId)
            const value = get(aggr, 'floatValue', null)
            let data = ''
            if (value === null) {
              data = '-'
            } else {
              const unitSI = get(dimension, 'physicalUnitSI', null)
              const physicalUnitUIMetric = get(dimension, 'physicalUnitUIMetric', null)
              const physicalUnitUIImperial = get(dimension, 'physicalUnitUIImperial', null)
              const unitUI = convertToLocalUnitSystem(this.selectedUIUnitSystem, physicalUnitUIMetric, physicalUnitUIImperial)
              const isDurationFormatAvailable = assetDimensionWithDurationFormat.includes(assetDimensionName)
              data =
                unitSI === 's' && !this.hasDecimalFormatEnabled && isDurationFormatAvailable
                  ? fullTimeDurationFormatter(value)
                  : units(value, unitSI, unitUI, 2, false, true, false, this.thousandsSeperator, this.decimalSeperator, true)
            }
            return data
          }),
        )
        return columns
      })
    },
    CSVStartDateRow() {
      return Array.from(Array(1)).map(() => {
        const extra = [this.$t('reporting.csv.startDate')]
        return extra.concat(
          this.assetDimensions.map(() =>
            this.selectedInterval.start.toLocal().setLocale(this.dateSettings).toLocaleString(DateTime.DATETIME_SHORT),
          ),
        )
      })
    },
    CSVEndDateRow() {
      return Array.from(Array(1)).map(() => {
        const extra = [this.$t('reporting.csv.endDate')]
        return extra.concat(
          this.assetDimensions.map(() =>
            this.selectedInterval.end.toLocal().setLocale(this.dateSettings).toLocaleString(DateTime.DATETIME_SHORT),
          ),
        )
      })
    },
    CSVDaysRow() {
      return Array.from(Array(1)).map(() => {
        const extra = [this.$t('reporting.csv.days')]
        const results = extra.concat(
          this.assetDimensions.map(() => {
            // Somehow it doesn't add the first day of the month, that is why I add the day in a check block below
            let data = Interval.fromDateTimes(this.selectedInterval.start, this.selectedInterval.end).toDuration('days').toObject().days

            // if it is a month
            if (data < 365 && data > 7) {
              data = data + 1
            }

            return Math.round(data)
          }),
        )
        return results
      })
    },
    messages() {
      const assetDimensionName = this.assetDimensions
        .map(dimension =>
          dimension.nameTranslations ? getAssetDimensionNameByLocale(dimension.nameTranslations, this.locale) : dimension.name,
        )
        .toString()
      let message = ''
      if (this.rows.length === 0) {
        message =
          this.searchQuery === ''
            ? this.$t('messages.noDimensionData', { dimension: assetDimensionName?.split(',').join(', ') })
            : this.$t('messages.searchNoResults', { query: this.searchQuery })
      }
      return message
    },
  },
  methods: {
    gridItemResized() {
      this.handleResize()
    },
    handleResize() {
      this.$nextTick(() => {
        const size = get(this.$el, 'clientWidth', 96)
        const isMobile = size < 600 && size >= 96
        this.isMobile = isMobile || (this.isPreview && this.isMobilePreview)
      })
    },
    tableExport(type) {
      if (type === 'csv') {
        const mappedHeaders = this.headers.map(header => ({ data: header.label }))
        const mappedRows = this.rows.flatMap(header => ({ data: header.cells })).flatMap(item => item.data)
        exportTableDataToCSV(
          mappedHeaders,
          mappedRows,
          `talpa-export_${this.selectedTimeframeParam.start}-${this.selectedTimeframeParam.end}.csv`,
        )
      }
    },
    textSearch(query) {
      this.searchQuery = query
    },
    setSelectedFilterOption(filter) {
      const found = this.filterOptionsSelected.find(f => f.id === filter.id)
      if (found) {
        this.filterOptionsSelected = this.filterOptionsSelected.filter(f => f.id !== filter.id)
      } else {
        this.filterOptionsSelected.push(filter)
      }
    },
    resetFilters() {
      this.filterOptionsSelected = []
    },
  },
  watch: {
    dimensions: {
      handler() {
        this.handleResize()
        this.dimensions.forEach(dimension => {
          const name = dimension?.assetDimension?.name ?? null
          if (name) {
            this.$apollo.addSmartQuery(name, {
              query: ASSET_DIMENSION_DATA_QUERY,
              variables() {
                return {
                  where: {
                    assetDimension: {
                      name,
                    },
                    timeframe: this.selectedTimeframeParam,
                    assets: {
                      id_in: this.assetIds,
                    },
                  },
                }
              },
              skip() {
                return this.dimensions.length < 1 || this.assetIds.length < 1 || !this.selectedTimeframe
              },
              result(res) {
                const total = res?.data?.assetDimensionData?.total ?? null
                const aggrByAsset = res?.data?.assetDimensionData?.aggrByAsset ?? []
                this.$set(this.totals, name, total)
                this.$set(this.aggrByAsset, name, aggrByAsset)
              },
              manual: true,
            })
          }
        })
      },
      immediate: true,
    },
    isMobilePreview: {
      handler() {
        this.handleResize()
      },
    },
  },
}
</script>
